import React from 'react'
import './Preloader.css';
export default function Preloader() {
  return (
    <div className="outline">
    <div className="loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  )
}
