import React from 'react'
import Footer from './../Footer/Footer';
import Nav from './../Navigation/Navigation';
export default function Projects() {
  return (
    <React.Fragment>
    <Nav/>
    <div style={{height:'80vh',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
      <h1 style={{color:'rgb(119, 107, 107)',fontSize:'35px'}}>comming soon !!!!</h1>
    </div>
    <Footer/>
    </React.Fragment>
  )
}
