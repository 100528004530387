import firebase from 'firebase';

var config = {
    apiKey: "AIzaSyDvnO4b2LhW_vtZmPO75UBlxVb3vVLAD9U",
    authDomain: "reference-unity-477.firebaseapp.com",
    databaseURL: "https://reference-unity-477.firebaseio.com",
    projectId: "reference-unity-477",
    storageBucket: "reference-unity-477.appspot.com",
    messagingSenderId: "474892789641"
  };

firebase.initializeApp(config);

export const database =firebase.database();
export const auth =firebase.auth();