import React, { Component } from 'react'

import {animateScroll as scroll} from 'react-scroll';
import Navigation from '../Navigation/Navigation';
import Footer from './../Footer/Footer';
import Me from './Me';
export default class AllBlogs extends Component {
  constructor(){
    super();
    this.state={
      loading:true,
   
    }
    this.scrollToTop = () => {
      scroll.scrollToTop(); 
  }
}
  
  render() {
    
    return (
      <React.Fragment>
        <Navigation/>
        <Me/>
        
        {/* <button style={{position:'fixed',top:'80px',left:'40px'}} onClick={this.scrollToTop}>'/\'</button> */}
        <Footer/>
      </React.Fragment>
    )
  }
}
