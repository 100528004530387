import React from 'react'
import {Link } from 'react-router-dom';

import './NAV.css';
export default function Navigation() {
  return (
    <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 navbg">
          <h5 className="my-0 mr-md-auto font-weight-normal" > 
          <Link to ='/' > <span className="brand-name">Ubaidh</span> </Link>
          <span className="hand">{'\u{1F44B}'}</span></h5>
          <nav className="my-2 my-md-0 mr-md-3"> 
            <a style={{fontWeight:"600"}} className=" p-2 text-dark" href="/blogs">Blogs</a>
            <a style={{fontWeight:"600"}} className=" p-2 text-dark" href="/cv">CV</a>
            <a style={{fontWeight:"600"}} className=" p-2 text-dark" href="/projects">Projects</a>
            <a style={{fontWeight:"600"}} className=" p-2 text-dark" href="/contact">Contact </a>
            
          </nav>
</div>
  )
}
