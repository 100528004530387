import React, { Component } from 'react'
import './Home.css';
class Home extends Component {s
    state={
            menu:false,
        }
     openmenu =()=>{
        const H = document.querySelector("div.Hero-menu");
        const over = document.querySelector('div.overlay');
         let showing = this.state.menu;
         this.setState({menu:!showing});
         if(!showing){
         H.classList.add('active');
         over.style.width='100%';
        }
        
         else{
            H.classList.remove('active');
            over.style.width='0%';
         }   
     }    
  render() {
    return (
        <div className="Hero">
                        <div className="Hero-logo">
                        {'\u{1F432}'}
                        </div> 
                    <div className="overlay">
                    <div className="overlay-content">
                        <a href="/blogs">Blog{' '} {'\u{1F468}\u{200D}\u{1F4BB}'}</a>
                        <a href="/cv">Resume{' '} {'\u{1F4F0}'}</a>
                        <a href="/projects">Works {' '} {'\u{1F432}'}</a>
                        <a href="/contact">Contact {' '} {'\u{1F4E7}'} </a>
                    </div>
                    </div> 
                            <div onClick={this.openmenu} className="Hero-menu">
                                <div className="block">
                                    <div className="cta">
                                    <div className="toggle-btn type16"></div>
                                    </div> 
                                </div>
                            </div>
                <div className="container-fluid px-5 header">
                <h1 className="mb-0">Ubaidh khatlani</h1>
                <h2>Software Developer {"\u{1F468}\u{200D}\u{1F4BB}"} designer</h2> 
                <ul className="mt-4 mb-0" >
                    <li className="icons_"><a href="https://github.com/ubaidh" rel="noopener noreferrer" target="_blank"><i className="fab fa-github-square "></i></a></li>
                    <li className="icons_"><a href="https://codepen.io/ubaidh" rel="noopener noreferrer" target="_blank"><i className="fab fa-codepen "></i></a></li>
                    <li className="icons_"><a href="https://twitter.com/ubaidh" rel="noopener noreferrer" target="_blank"><i className="fab fa-twitter "></i></a></li>
                    <li className="icons_"><a  href="mailto:obaidh@live.com?Subject=Hello%20from%20ubaidh.com" target="_top"><i className="far fa-envelope"></i></a></li>  
                </ul>
                </div>

      </div>
     
    )
  }
}

export default Home;