import React, { Component } from 'react'
import {auth}from './../../../Firebase';
import swal from 'sweetalert';
import './Login.css';

export default class Login extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:false
        }
        
        this.emailref = React.createRef();
        this.passwordref=React.createRef();
        this.logmein=(e)=>{
            this.setState({loading:true});
            e.preventDefault();
            console.log(this.state.loading)
            let emailid=this.emailref.current.value;
            let password = this.passwordref.current.value;
            if(emailid !=="" && password !==""){
              auth.signInWithEmailAndPassword(emailid, password)
            .then((user)=>{
                if(user){
                  this.props.history.push("/addblog");
                }      
            })            
            .catch((error)=> {
                // Handle Errors here.
                var errorCode = error.code;
                swal("error !", `${errorCode}`, "error");
                var errorMessage = error.message;
                swal("error !", `${errorMessage}`, "error");
                this.setState({loading:false})
                // ...
               });
            }else{
              swal("error !", `enter both email and password `, "error");
              this.setState({loading:false})
              console.log(this.state.loading)
              this.emailref.current.focus();
        }
       
        }
        
      
    }
    
  render() {
    auth.onAuthStateChanged((user)=> {
      if (user) {
          this.props.history.push("/addblog");
      } 
    });
    return (
        <div className="bd">
        <form  className="form-signin">    
      <div className="form-label-group">
        <input ref={this.emailref} type="email" id="inputEmail" className="form-control" placeholder="Email address" required="" autoFocus=""/>
        <label htmlFor="inputEmail">Email address</label>
      </div>
    
      <div className="form-label-group">
        <input ref={this.passwordref} type="password" id="inputPassword" className="form-control" placeholder="Password" required=""/>
        <label htmlFor="inputPassword">Password</label>
      </div>
    
      <div className="checkbox mb-3">
        <label>
          <input type="checkbox" value="remember-me"/> Remember me
        </label>
      </div>
      <button onClick={this.logmein} className="btn btn-lg btn-primary btn-block" type="submit">{this.state.loading ? `loading....` :"Sign in "}</button>
      <p className="mt-5 mb-3 text-muted text-center">© 2017-2018</p>
    </form>
    </div>
    )
  }
}
