import React, { Component } from 'react'
import {auth} from '../../../Firebase';
export default class Addblog extends Component {
    constructor(){
        super();
        this.state={

        }
        this.signout=()=>{
            auth.signOut().then(this.props.history.push("/login"))
    }
   

   }
  render() {
    auth.onAuthStateChanged((user)=> {
        if (!user) {
            this.props.history.push("/login");
        } 
      });
    return (
      <div>
        <h1>add blog</h1>
        <button onClick={this.signout}>logout</button>
      </div>
    )
  }
}
