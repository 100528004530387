import React, { Component } from 'react'
import './Me.css';
import Pro from './IMG_5722.JPG';
import swal from 'sweetalert';
export default class Me extends Component {
  sww=()=>{
    swal({
      icon:Pro,
      button: "Close!",
    })
   
  }
  render() {
    return (
      <div className="container mt-3">
      <div className="row">
      <div className="col-12 col-md-9 col-xl-8 py-md-3 pl-md-5 bd-content mx-auto pb-3">
      <img onClick={this.sww}src={Pro} style={{height:'150px',cursor:'pointer',boxShadow:"#806249 9px 3px 14px 0px"}} className=" rounded float-left" alt="..."/>
      </div>
      <div className="col-12 col-md-9 col-xl-8 py-md-3 pl-md-5 bd-content mx-auto">
      <div className="bodyline">
      <div className="heading"><h1 className="myname mb-3">Hi im ubaid</h1></div>
      <div className="bioline"><p className="biolinep">I develop software with and for people .<br/>
        I'm web designer & full stack developer .
       I'm interested in all kinds of visual communication, but my major focus is on designing web, mobile 
       & tablet interfaces.
      </p>
      <blockquote className="blockquote text-center">
  <p className="mb-0">Choose a job you love, and you will never have to work a day in your life..</p>
  <footer className="blockquote-footer">Confucius <cite title="Source Title">Book of Rites</cite></footer>
</blockquote>
      </div>
      </div>
      </div>
      </div>
      <hr/>
      </div>
    )
  }
}
