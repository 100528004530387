import React, { Component } from 'react';
import Navigation from './../Navigation/Navigation';
import Footer from './../Footer/Footer';
import Preloader from './../Preloader/Preloader';
import './Blog.css';
export default class Blog extends Component {
  constructor(){
    super();
    this.state={
      blog:[],
      loading:true
    }
    this.goBack=()=>{
      this.props.history.goBack();
    }
    this.goForward=()=>{
      this.props.history.goForward();
    }
  }
  componentWillMount(){
    var post = this.props.match.params.id;
    fetch(`https://jsonplaceholder.typicode.com/posts/${post}`)
    .then(res=>res.json()).then(res=>this.setState({blog:res}));
    console.log("will",this.state.loading)
    
  }
  
  
  componentDidMount(){
    
    this.setState((prev)=>({loading:!prev}))
  
    console.log("did",this.state.loading)
  
  }
  render() {
    var {title,body} = this.state.blog;
    var loading = this.state.loading;
    console.log("render",loading)
    return (
      <React.Fragment> 
      <Navigation/>
      <article style={{position: 'inherit',minHeight:'80vh'}}>
          {loading ? 
         <Preloader/>
          :<div className="container z">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
            <div className="blog-post">
            <h1 className="blog-post-title">{title}</h1>
            <hr/>
            <p style={{fontFamily:'inherit,',fontSize:'18px',lineHeight:'30.6px',fontWeight:'400',color:'rgb(25,25,25)'}}>{body}</p>
            </div>
          </div>
        </div>
        </div> }

    </article>
    <Footer/>
    </React.Fragment>     
    )
  }
}
