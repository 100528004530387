import React, { Component } from 'react';
import heart from './Heart-1s-33px.svg';
import {animateScroll as scroll} from 'react-scroll';
import './Footer.css';
export default class Footer extends Component {
  constructor(){
    super();
  this.scrollToTop = () => {
    scroll.scrollToTop(); 
}
  }
  render() {
   
    return (
        <div className="footer">
       <div className="wrapper">
       <div className="winter-wrapper">
       <div className="winter-wrap">
       <div className="sun"></div>       
       <div className="bottom-snow"><span></span></div>
       <div className="middle-snow"></div>
       <div className="swing"></div>
       <div className="swing-over-wrap">
       <div className="swing-over"><span></span><span></span><span></span></div></div>
       <div className="swing-left"></div>
       <div className="swing-left-back"><span></span><span></span><span></span><span></span></div>
       <div className="bottom-snow-over"><span></span></div>
       <div className="box"><span></span><span></span><span></span><span></span><span></span><span></span>
       <div className="bucket"><span></span><span></span></div>
       <div className="shovel"><span></span><span></span><span></span></div></div>
       <div className="goup" onClick={this.scrollToTop}> {'\u{1F53A}'} </div>
       <div className="lamp">
       <div className="lamp-wrap"><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span></div></div>
       <div className="bench">
       <div className="bench-wrap"><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span></div></div>
       <div className="bin"><span></span><span></span></div>
       <div className="tree"><span></span><span></span><span></span><span></span><span></span><span></span></div>
       <div className="tree right"><span></span><span></span><span></span><span></span><span></span><span></span></div></div>
       </div>
       </div>
       <div className="credit">
       <div >designed and developed with <img src={heart}alt="heart"/> by  <a  rel="noopener noreferrer" target="_blank" className="designerwebsite pl-1" href="http://www.ubaidh.com" >ubaidh</a> </div> 
       </div>
       </div>
        
    )
  }
}
