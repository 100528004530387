import React, { Component } from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';
import HomeFull from './Components/Home/HomeFull';
import E404 from './Components/404/E404';
import AllBlogs from './Components/Blog/AllBlogs';
import Resume from './Components/Resume/Resume';
import Blog from './Components/Blog/Blog';
import Contact from './Components/Contact/Contact';
import Login from './Components/Backend/Admin/Login';
import Addblog from './Components/Backend/Blogentry/Addblog';
import Projects from './Components/Projects/Projects';
import './App.css';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-133821871-1');
ReactGA.pageview(window.location.pathname + window.location.search);



class App extends Component {
  constructor(){
    super();
    this.state={
      show:false
    }
   
  }
 
  render(){ 
    return (
      <div>
        
        <BrowserRouter>
        <Switch>
        <Route exact path='/' component={HomeFull}/>
        <Route exact path='/blogs' component={AllBlogs}/>
        <Route exact path='/blog/:id' component={Blog}/>
        <Route exact path='/cv' component={Resume}/>
        <Route exact path='/contact' component={Contact}/>
        <Route exact path='/login' component={Login}/>
        <Route exact path = '/projects' component={Projects}/>
        <Route exact path='/addblog' component={Addblog}/>
        <Route component={E404}/>
        </Switch>
        </BrowserRouter>
        
        
        
        
      </div> 
    );
  }
  }
export default App;
