import React, { Component } from 'react'
import Navigation from './../Navigation/Navigation';
import Footer from './../Footer/Footer';
import { database } from './../../Firebase';
import swal from 'sweetalert';
import './Contact.css';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-133821871-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default class Contact extends Component {
  constructor(){
    super();
    this.state={
      message:{},
      btnisloading:false

    }
    this.emailRef = React.createRef();
    this.messageRef = React.createRef();
    
    this.pushmessage=(e)=>{
      e.preventDefault();
      
      let email = this.emailRef.current.value;
      let message = this.messageRef.current.value;
      let mtime = Date.now();
     
      if(email !== "" && message !==""){
        this.setState({btnisloading:true})
        let messages ={"email":email,"message":message,"timestamp":mtime}
        this.setState({message:messages});
        const data = database.ref().child("messages");
        data.push(messages, (error)=> {
          if (error){
            swal("error !", "error while sending message", "error");
            this.setState({btnisloading:false})
          }
          else{
          swal("thank you!", "message recived!", "success");
          this.setState({btnisloading:false})
          this.emailRef.current.value="";
          this.messageRef.current.value=" ";
          this.emailRef.current.focus();
          }
        })
        }else{
          swal("error!", "typ both email and message!", "info");
        }
        
        
        
      }
     
    
  }
 
 
  render() {
    return (
      <div className="contactpage">
          <Navigation/>

           <div style={{textAlign: "center!important"}} className="contact">
           <form onSubmit={this.pushmessage}  className="form-signin mt-3">
            <h1  className="h3 mb-3  font-weight-normal ">Leave a message</h1>
            <label htmlFor="inputEmail" className="sr-only">Email address</label>
            <input ref={this.emailRef} type="email" id="inputEmail" className="form-control" placeholder="Email address" required="" autoFocus=" "/>
            <label htmlFor="inputPassword" className="sr-only">Password</label>
            <textarea ref={this.messageRef} type="textarea" rows="7" className="form-control" placeholder="message" required="">
            </textarea>
            <button onSubmit={this.pushmessage} 
            className="btn btn-lg btn-primary btn-block" 
            type="submit">{this.state.btnisloading ? "sending...." : "Send "}</button>
            <p style={{textAlign:"center"}}className="mt-5 mb-3 text-muted">© 2018-2019</p>
             </form>
           </div>


          <Footer/>
      </div>
    )
  }
}
