import React from 'react'
import Home from './Home';
import Base from './Base';
import Preloader from './../Preloader/Preloader';
import Footer from './../Footer/Footer';
import ReactGA from 'react-ga';
import Me from './../Blog/Me';
ReactGA.initialize('UA-133821871-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default class HomeFull extends React.Component{
  constructor(){
    super();
      this.state={
        loading:true
      }
  }
  componentDidMount(){
    this.setState({loading :false})
  }
  render(){
  let loading =this.state.loading;
  if(loading){
    return(<Preloader/>)
  }else{
  return (
    <React.Fragment>
        <Home/>
        <Base/>
        <Me/>
        <Footer/>
    </React.Fragment>
  )}
}
}
