import React from 'react'
import Navigation from './../Navigation/Navigation';
import Footer from './../Footer/Footer';
import './E404.css';
export default function E404() {
  const E404={
    minHeight:'90vh',
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"row", background: "#03a9f4" 
}
  return (
    <React.Fragment>
      <Navigation/>
      <div style={E404}>
            <h1>404</h1>
      </div>
      <Footer/>
    </React.Fragment>
    
  )
}
