import React from 'react'

export default function CV() {
  return (
      <div className="container d-flex justify-content-center">
      <div className="col-md-8 col-sm-12 col-xl-06 ">
      <section style={{marginTop:'3rem',marginBottom:'3rem'}}>
      <h2>SKILLS</h2><p><strong>Languages:</strong> javascript, Golang , C, C++, Python, HTML, CSS</p>
      <p><strong>Web Development Technologies:</strong> Node.js, Express, React,  Babel, Webpack, SCSS, Bootstrap ,Css in JS (Styled Components, JSS, Css modules, ...)</p>
      <p><strong>Mobile Development:</strong> React Native (Android and iOS) beginner</p><p>
        <strong>Databases:</strong> MySQL, MongoDB, Firebase</p>
      <p><strong>Others:</strong> Git,Github, opencv python ,c++,numpy,matplotlib</p>
      <p><strong>Soft skills:</strong> Good at communication, Team player</p>
      <p>Currently looking into &#8203;Distributed Systems, Software Architecture, Functional Programming, Reactive Programming</p></section>
      <section style={{marginTop:'3rem',marginBottom:'3rem'}}>
      <hr/>
      <h2>EDUCATION</h2><p>Masters degree in computer applications ,<br/> Bangalore University in bangalore Karnataka<br/>2010-2013</p>
      <p>Bachelors degree in computer applications ,<br/> kashmir University in srinagar kashmir<br/>2007-2010</p>
      </section>
      </div> 
      </div>
  )
}
