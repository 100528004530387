import React, { Component } from 'react'
import Nav from './../Navigation/Navigation';
import Footer from './../Footer/Footer';
import CV from './CV';
export default class Resume extends Component {
  render() {
    return (
      <div>
        <Nav/>
        <CV/>
        <Footer style={{bottom:'0'}}/>
      </div>
    )
  }
}
